import { styled } from "@mui/material";
import { breakpoint } from "../../components/styled-components/Breakpoints/breakpoints";

/* Styled Components for the Contact page */

//nav height: 60px

export const MainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Helvetica;
  box-sizing: border-box;
  padding: 3vw 0;
  align-items: center;
  min-height: calc(100vh - 60px);
  justify-content: flex-start;

  ${breakpoint(
    "xxxlarge+",
    `
      padding: 1vw 0;
  `
  )}
`;

export const HeaderSection = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 90%;
  padding: 1vh;
  height: 100%;
  overflow: visible;

  ${breakpoint(
    "medium-",
    `
    flex-direction: column;
    align-items: center;
  `
  )}
`;

export const LeftSection = styled("div")`
  flex: 1;
  order: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoint(
    "medium-",
    `
    width: 100%;
    margin-bottom: 1vw;
    order: 2;
  `
  )}
`;

export const RightSection = styled("div")`
  flex: 1;
  order: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1vh;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  ${breakpoint(
    "medium-",
    `
    width: 100%;
    order: 1;
  `
  )}
`;

export const MapImage = styled("img")`
  width: 100%;
  height: auto;
  object-fit: cover;

  ${breakpoint(
    "xxxlarge+",
    `
    width: 85%;
  `
  )}

  ${breakpoint(
    "medium-",
    `
padding: 20px 0;  `
  )}
`;

export const AddressRow = styled("div")`
  display: flex;
  border-radius: 1.5vw;
  align-items: center;
  align-self: center;
  justify-content: center;
  text-align: center;
  margin-top: 3vh;

  @media (max-width: 1300px) {
    flex-direction: column;
    width: 95%;
  }

  ${breakpoint(
    "medium-",
    `
padding: 10px;  `
  )}
`;

export const AddressBlock = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25vw;
`;

export const AddressItem = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 5px;

  @media (max-width: 1300px) {
    flex-direction: row;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    text-align: center;
    width: 100%;
  }
`;

export const AddressHeader = styled("span")`
  color: #ffb310;
  font-size: 1.7rem;
  letter-spacing: 1px;
  justify-content: center;
  break-after: always;
`;

export const AddressSubHeader = styled("span")`
  color: white;
  font-size: 1.5rem;
  letter-spacing: 1.5px;
  justify-content: center;
  break-after: always;
  font-weight: 600;
`;

export const Address = styled("span")`
  color: white;
  font-size: 1.5rem;
  letter-spacing: 1.5px;
  justify-content: center;
  font-weight: 300;
`;

export const PageHeader = styled("span")`
  font-size: 3vh;
  font-weight: 600;
  margin: 0 auto;
  text-align: center;
  padding: 10px;

  ${breakpoint(
    "medium-",
    `
      font-size: 22px;
  `
  )}
`;

export const Subtext = styled("span")`
  font-size: 1vw;
  font-weight: 300;
  margin: 0 auto;
  text-align: center;
  padding: 10px;

  ${breakpoint(
    "medium-",
    `
      font-size: 14px;
      padding: 10px 10px 0;
  `
  )}
`;
