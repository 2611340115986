import { Box, Button, MenuItem, styled, TextField } from "@mui/material";
import { breakpoint } from "../../../components/styled-components/Breakpoints/breakpoints";
import { Field, FieldProps } from "formik";
import React from "react";

export const StyledBox = styled(Box)({
  width: "100%",
  margin: 0,
  "& .MuiBox-root": {
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
  },
});

export const StyledTextField = styled(TextField)({
  marginBottom: 6,
  "& .MuiInput-underline:before": {
    borderBottomColor: "#ffb310",
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "#ffb310",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ffb310",
  },
  "& .MuiInputLabel-root": {
    color: "#ffb310",
    fontSize: "0.8vw",
    "@media (max-width: 989px)": {
      fontSize: "12px",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#ffb310",
    opacity: 1,
    fontSize: "0.8vw",

    "@media (max-width: 989px)": {
      fontSize: "12px",
    },
  },
  "& .MuiInputLabel-shrink": {
    color: "#ffb310",
  },
  "& .Mui-focused .MuiInputLabel-root": {
    color: "#ffb310",
  },
  "& .MuiInputBase-input": {
    color: "#f6f6f6",
    fontSize: "0.8vw",
    "@media (max-width: 989px)": {
      fontSize: "12px",
    },
  },
  "& .Mui-focused .MuiInputBase-input::placeholder": {
    color: "#ffb310",
    opacity: 1,
  },
  "& .MuiInput-underline.Mui-focused:after": {
    borderBottomColor: "#ffb310",
  },
  "& .MuiInput-underline.Mui-focused:before": {
    borderBottomColor: "#ffb310",
  },
  "&:-webkit-autofill": {
    "-webkit-box-shadow": "0 0 0 1000px #1e1e1e inset !important",
    "-webkit-text-fill-color": "#f6f6f6 !important",
    backgroundColor: "black !important",
  },
});

interface StyledButtonProps {
  $isSubmitted?: boolean;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  background-color: ${(props) => (props.$isSubmitted ? "black" : "#ffb310")};
  color: ${(props) => (props.$isSubmitted ? "#ffb310" : "black")};
  border: ${(props) => (props.$isSubmitted ? "2px solid #ffb310" : "none")};
  transition: all 0.3s ease;
  width: 100%;

  &:hover {
    background-color: ${(props) => (props.$isSubmitted ? "black" : "#e6a00e")};
    color: ${(props) => (props.$isSubmitted ? "#ffb310" : "black")};
    border: ${(props) => (props.$isSubmitted ? "2px solid #ffb310" : "none")};
  }

  &:disabled {
    background-color: ${(props) => (props.$isSubmitted ? "black" : "#ccc")};
    color: ${(props) => (props.$isSubmitted ? "#ffb310" : "#666")};
    border: ${(props) => (props.$isSubmitted ? "2px solid #ffb310" : "none")};
    opacity: ${(props) => (props.$isSubmitted ? "1" : "0.7")};
  }
`;
export const TopRow = styled("div")`
  display: flex;
  width: 100%;
  gap: 15px;

  ${breakpoint(
    "medium-",
    `
   flex-direction: column;
  `
  )}
`;

export const FormWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  // gap: 15px;
  width: 100%;
`;

export const RecaptchaContainer = styled("div")<{ width: number | null }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;

  ${breakpoint(
    "medium-",
    `
      flex-direction: column;
    `
  )}
`;

export const StyledMenuItem = styled(MenuItem)({
  fontSize: "1vw",
  "@media (max-width: 989px)": {
    fontSize: "14px",
  },
});

interface StyledFormikTextFieldProps {
  name: string;
  label: string;
  fullWidth?: boolean;
  variant?: "standard" | "outlined" | "filled";
  multiline?: boolean;
  rows?: number;
  select?: boolean;
  children?: React.ReactNode;
  lineHeight?: string;
}

export const StyledFormikTextField: React.FC<StyledFormikTextFieldProps> = ({
  label,
  ...props
}) => (
  <Field name={props.name}>
    {({ field, meta }: FieldProps) => (
      <StyledTextField
        {...field}
        {...props}
        label={label}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        variant="standard"
        InputLabelProps={{ shrink: !!field.value }}
      />
    )}
  </Field>
);
