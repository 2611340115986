import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { logAnalytics } from "../../../firebase";
import { Typography } from "@mui/material";
import {
  FormWrapper,
  RecaptchaContainer,
  StyledBox,
  StyledButton,
  StyledFormikTextField,
  StyledMenuItem,
  TopRow,
} from "./ContactForm.styles";
import { mailingList } from "../../../utils/vars";

declare module "yup" {
  interface ArraySchema<T> {
    unique(
      this: Yup.ArraySchema<Yup.AnySchema>,
      message: string,
      mapper?: (a: T) => any
    ): Yup.ArraySchema<T>;
  }
}

Yup.addMethod(Yup.array, "unique", function (message, mapper = (a: any) => a) {
  return this.test("unique", message, function (list) {
    const context: Yup.TestContext = this; // Provides access to the context
    if (!Array.isArray(list)) {
      return this.createError({ path: context.path, message: message });
    }
    const mappedList = list.map(mapper);
    const isUnique = mappedList.length === new Set(mappedList).size;
    return (
      isUnique || this.createError({ path: context.path, message: message })
    );
  });
});

const SignupSchema = Yup.object().shape({
  name: Yup.string().min(2, "Enter your full name").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  recaptchaToken: Yup.string().when("$isSubmitting", {
    is: true,
    then: Yup.string().required("ReCAPTCHA verification failed"),
    otherwise: Yup.string(),
  }),
});

export default function ContactForm({
  isLandingPage,
}: {
  isLandingPage?: boolean;
}) {
  const topics = ["New Project", "Joining Our Team", "General Inquiries"];
  const reCaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const verifyRecaptcha = async () => {
    if (!reCaptchaRef.current) {
      return null;
    }
    try {
      const token = await reCaptchaRef.current.executeAsync();
      return token;
    } catch (error) {
      return null;
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
        email: "",
        topic: "",
        message: "",
        recaptchaToken: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={async (
        { name, phone, email, topic, message },
        { setFieldValue, resetForm }
      ) => {
        setIsSubmitted(true);
        const token = await verifyRecaptcha();

        if (!token) {
          setFieldValue("recaptchaToken", "");
          setIsSubmitted(false);
          return;
        }
        try {
          const verificationResponse = await axios.post(
            `${process.env.GATSBY_firebase_function_domain}/recaptcha-verifyRecaptcha`,
            { token }
          );

          if (
            !verificationResponse.data.success ||
            verificationResponse.data.score < 0.5
          ) {
            console.error("Verification failed:", verificationResponse.data);
            return;
          }
          logAnalytics("requested_quote");

          await Promise.all([
            axios.post(
              `${process.env.GATSBY_firebase_function_domain}/smtpMailers-sendMail`,
              {
                destination: email,
                bcc: ["info@roboticimaging.com", ...mailingList],
                subject: "New Contact",
                text: `Thanks for reaching out to Robotic Imaging! We look forward to connecting!
                        Here's a copy of the information you've sent to us:
                        Name: ${name}
                        Phone: ${phone}
                        Email Address: ${email}
                        Topic: ${topic}
                        Message: ${message} `,
                html: `
                        <div>Thanks for reaching out to Robotic Imaging! We look forward to connecting!</div>
                        <br />
                        <div>Here's a copy of the information you've sent to us:</div>
                            <div>Name: ${name}</div>
                            <div>Phone: ${phone}</div>
                            <div>Email Address: ${email}</div>
                            <div>Topic: ${topic}</div>
                            <div>Message: ${message}</div>`,
              }
            ),
          ]);

          setIsSuccess(true);
          setTimeout(() => {
            resetForm();
            setIsSubmitted(false);
            setIsSuccess(false);
          }, 6000);
        } catch (err) {
          setIsSubmitted(false);
          setIsSuccess(false);
          console.error("Form submission error:", err);
        }
      }}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form style={{ width: "100%" }}>
          <StyledBox my={4}>
            <FormWrapper>
              <TopRow>
                <StyledFormikTextField name="name" fullWidth label="Name" />
                <StyledFormikTextField name="phone" fullWidth label="Phone" />
              </TopRow>
              <StyledFormikTextField name="email" fullWidth label="E-mail" />
              <StyledFormikTextField
                name="topic"
                fullWidth
                label="Topic"
                select
              >
                {topics.map((topic) => (
                  <StyledMenuItem key={topic} value={topic}>
                    {topic}
                  </StyledMenuItem>
                ))}
              </StyledFormikTextField>
              <StyledFormikTextField
                name="message"
                fullWidth
                label="Message"
                multiline
                rows={!isLandingPage ? 4 : 3}
              />
              <RecaptchaContainer width={null}>
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey={process.env.GATSBY_recaptchaSiteKey || ""}
                  size="invisible"
                  badge="bottomright"
                />
                <StyledButton
                  type="submit"
                  variant="contained"
                  $isSubmitted={isSubmitted}
                  disabled={isSubmitted}
                >
                  {isSubmitted ? "Submitted!" : "Submit"}
                </StyledButton>
              </RecaptchaContainer>
              {errors.recaptchaToken && touched.recaptchaToken && (
                <Typography color="error" textAlign="center">
                  {errors.recaptchaToken}
                </Typography>
              )}
              {isSuccess && (
                <Typography color="primary" textAlign="center">
                  Form submitted successfully! Resetting form fields in 6
                  seconds...
                </Typography>
              )}
            </FormWrapper>
          </StyledBox>
        </Form>
      )}
    </Formik>
  );
}
